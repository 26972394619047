<template>
  <div class="registration">
    <BannerKv :titleEn="BannerKvData.titleEn" :titleZh="BannerKvData.titleZh"
      :backgroundPic="BannerKvData.backgroundPic" />
    <!-- <RegistrationStep :step="1" /> -->
    <div class="w1200 registration__content">
      <div class="registration__title">
        <Title class="registration__title-text" :label="$t('selectDoctorAndTime')" />
        <!-- <Schedule class="registration__title-schedule" :classification="classification" /> -->

      </div>
      <SelectClinicDateDoctor :selectType="selectType" @update="methodUpdateSelectType" />
      <div class="registration__select">
        <component :is="components[selectType]" @select="methodSelectSchedule" :type="classification" />
      </div>
    </div>

    <section id="registration_btnbox">
      <div class="w1000 box">
        <router-link to="/registration" class="btn btn-dark p" :class="[lang === 'en' ? 'btnEn' : '']">
          {{ $t("return") }}
        </router-link>
        <button type="button" class="btn p" :class="[lang === 'en' ? 'btnEn' : '']" v-if="!isMobile"
          @click="confirmHandler">
          {{ $t("confirmOutpatient") }}
        </button>
      </div>
    </section>

    <re-dialog :visible.sync="visible.loginSignupNotification" footerPosition="center" :template="PleaseLoginOrSignup" />
  </div>
</template>

<style>
.drag_verify .dv_text {
  color: #fff !important;
}
</style>
<script>
import '@/assets/scss/registration.scss';
import BannerKv from '@/components/BannerKv.vue';
// import RegistrationStep from '@/components/registration/RegistrationStep.vue';
import Schedule from '@/components/Schedule.vue';
import Title from '@/components/Title.vue';
import SelectClinicDateDoctor from '@/components/SelectClinicDateDoctor.vue';
import ByClinic from '@/components/selectSchedule/byClinic/Index.vue';
import ByDate from '@/components/selectSchedule/byDate/Index.vue';
import ByDoctor from '@/components/selectSchedule/byDoctor/Index.vue';
import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';
import { isMobile } from 'mobile-device-detect';
import { mapMutations, mapState } from 'vuex';
import pic01 from '@/assets/img/index/imglink_1.jpg';
import registrationProhibitMixin from '@/registrationProhibitMixin';
import ReDialog from '@/components/dialog/ReDialog.vue';
import PleaseLoginOrSignup from '@/components/dialog/content/PleaseLoginOrSignup.vue';
import { getMemberProfileV2 } from '@/lib/cakeHttp';

export default {
  name: 'RegistrationChoose',
  components: {
    BannerKv,
    // RegistrationStep,
    DatePick,
    Schedule,
    Title,
    SelectClinicDateDoctor,
    ReDialog
    // dragVerify,
  },
  props: ['classification'],
  data() {
    return {
      visible: {
        loginSignupNotification: false,
      },
      isMobile,
      PleaseLoginOrSignup,
      BannerKvData: {
        titleEn: 'ONLINE REGISTRATION',
        titleZh: this.$t('onlineBooking'),
        backgroundPic: pic01
      },
      countryCode: [],
      components: {
        1: ByClinic,
        2: ByDate,
        3: ByDoctor
      },
      selectedSchedule: null
    };
  },
  computed: {
    ...mapState(['lang']),
    selectType() {
      const { type = 1 } = this.$route?.query || {};
      return +type;
    }
  },
  mixins: [registrationProhibitMixin],
  methods: {
    ...mapMutations(['setAppointmentData', 'setIsInAppointementProgress', 'logIn']),
    openDialog(type) {
      this.visible[type] = true;
    },
    checkLogin() {
      const isLogin = this.$cookies.get('api_token');

      if (isLogin) {
        return true;
      }

      return false;
    },
    confirmHandler() {
      if (!this.selectedSchedule) {
        this.selectTimeWarning();
        return;
      }

      const submitData = {
        item_id: this.classification,
        devision_id: this.classification,
        ...this.selectedSchedule
      };

      this.setAppointmentData(submitData);

      if (!this.checkLogin()) {
        this.openDialog('loginSignupNotification');
        this.setIsInAppointementProgress(true);
        return;
      }

      if (!this.checkPrivacy() || !this.checkQualificationV3()) {
        this.openkQualificationPop();
        return;
      }
      if (!this.checkQualificationV3()) {
        return;
      }

      this.$router.push({ name: 'RegistrationConfirm' });

      // if (!this.selectedSchedule) {
      //   this.selectTimeWarning();
      // } else {
      //   const submitData = {
      //     item_id: this.classification,
      //     devision_id: this.classification,
      //     ...this.selectedSchedule
      //   };

      //   this.setAppointmentData(submitData);
      //   this.$router.push({ name: 'RegistrationConfirm' });
      // }
    },
    methodUpdateSelectType(v) {
      this.$router.push({
        query: { type: v }
      });

      this.selectedSchedule = null;
    },
    methodSelectSchedule(v) {
      this.selectedSchedule = v;
      if (this.isMobile) {
        this.confirmHandler();
      }
    },
  },
  beforeMount() {
    if (this.checkLogin() && (!this.checkPrivacy() || !this.checkQualificationV3())) {
      getMemberProfileV2(
        (response) => {
          console.log(response);
          this.logIn({
            member: response
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.registration {

  // &__content {
  //   width: 100%;
  //   max-width: 1200px;
  //   margin: 0 auto;
  //   padding: 0 25px;
  // }
  &__title {
    padding: 38px 0px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "left center right";
    justify-items: center;

    @media screen and (max-width: 768px) {
      & {
        grid-template-columns: auto;
        grid-template-rows: repeat(3, 1fr);
        grid-template-areas:
          "left"
          "center"
          "right";
      }
    }

    &-text {
      grid-area: center;
    }

    &-schedule {
      grid-area: right;
      justify-self: flex-end;
    }
  }

  &__select {
    margin: 14px 0px;
  }
}
</style>
