<template>
  <div id="registration_lightbox" class="" v-show="show">
    <div class="cover" @click="close()"></div>
    <div class="box schedule-imgs-modal">
      <img src="@/assets/img/delete.svg" class="light_box_close" @click="close()" />
      <div class="schedule-imgs-modal__content">
        <p class="schedule-imgs-modal__title">{{ $t("schedule") }}</p>
        <NwSelect
          :selected="clinicSelected"
          :list="clinicList"
          :placeholder="`${$t('select')}${$t('registrationBranch')}`"
          @update="methodUpdateClinicSelected"
        />
        <div class="schedule-imgs-modal__imgs-wrapper">
          <div class="schedule-imgs-modal__img">
            <img :src="imgLink" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NwSelect from '@/components/NwSelect.vue';

const API_URL = process.env.VUE_APP_API_NEW;

export default {
  name: 'ScheduleImgsModal',
  props: ['show', 'type'],
  components: {
    NwSelect
  },
  data() {
    return {
      clinicSelected: null,
      imgLink: ''
    };
  },
  computed: {
    ...mapState(['branchs']),

    clinicList() {
      return this.branchs.filter(({ clinic }) => clinic.includes(+this.type));
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    // 更新選擇的診所
    methodUpdateClinicSelected(v) {
      this.imgLink = `${API_URL}${v.devisions[`_${this.type}`]}`;

      this.clinicSelected = v;
    }
  }
};
</script>

<style lang="scss" scoped>
#registration_lightbox {
  .schedule-imgs-modal {
    &.box {
      &::before {
        display: none;
      }
      min-height: 50vh;
      height: auto;
      min-width: 60vw;
      // height: 100%;

      @media screen and (max-width: 1200px) {
        max-width: 90vw;
        width: 100%;
      }
    }
    &__title {
      font-size: 18px;
      margin-bottom: -6px;
      color: #707070;
    }
    &__content {
      overflow-y: auto;
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      // height: 100%;
      // gap: 20px;
    }
    &__imgs-wrapper {
      // display: grid;
      // grid-template-columns: repeat(2, minmax(0, 1fr));
      // gap: 10px;
      @media screen and (max-width: 1200px) {
        // grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }
    &__img {
      img {
        border-radius: 8px;
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
