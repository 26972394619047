<template>
  <div class="c-email-unverified">
    <div class="c-email-unverified__content">
      <div class="warning-wrap">
        <div class="warning">
          <img class="warning__icon" src="@/assets/icons/warning.svg" />
        </div>

        <div class="desc">
          <p class="desc__text">{{ $t('loginForRegistration') }}</p>
        </div>
      </div>

      <div class="btn-wrap">
        <div class="btn-wrap__box">
          <re-button round="round" type="border" @click="toLoginPage" :style="{ width: '120px' }">
            {{ $t('pleaseLoginBtn') }}
          </re-button>
        </div>
        <div class="btn-wrap__box">
          <re-button round="round" type="border" @click="toSignupPage" :style="{ width: '120px' }">
            {{ $t('signUp') }}
          </re-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ReButton from '@/components/form/ReButton.vue';

export default {
  name: 'PleaseLogin',
  components: {
    ReButton
  },
  methods: {
    toLoginPage() {
      this.$router.push({
        name: 'Login'
      });
    },
    toSignupPage() {
      this.$router.push({
        name: 'Signup'
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";

.c-email-unverified {
  @include padding(30px);
}

.btn-wrap {
  margin-top: 30px;
  @include flex(center, center);

  &__box {
    &+& {
      margin-left: 10px;
    }
  }
}

.warning-wrap {
  @include flex(center, center, column);
}

.warning {
  @include padding(0 0 20px);

  &__icon {
    width: 50px;
  }
}

.desc {

  &__text {
    @include font-style($c-black, 16, 400, 1px, 24px);
  }
}
</style>
