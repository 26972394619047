<template>
  <h5 class="txt-bold" v-show="label!=undefined">
    {{label}}
    <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="">
  </h5>
</template>

<script>
export default {
  name: 'Title',
  props: {
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>

</style>
