<template>
  <div class="by-wrapper">
    <div class="by-wrapper__select">
      <NwSelect :selected="weekSelected" :list="weekList" :placeholder="$t('customSWALSelectDate')"
        @update="methodUpdateWeekSelected" compareKey="value" />
      <NwSelect :selected="dateSelected" :list="dateList" :placeholder="$t('customSWALSelectDate')"
        @update="methodUpdateDateSelected" compareKey="value" />
      <NwSelect :selected="periodSelected" :list="rangeList" placeholder="請選擇時段" @update="filterPeriod"
        compareKey="value" />
    </div>
    <template v-if="dateSelected">
      <hr class="hr-default" />
      <!-- <ChangeDays :currentDay="dateSelected" :days="dateList" @update="methodUpdateDateSelected" /> -->
      <!-- <ByDateCalendar :clinicList="clinicList" :date="dateSelected" :schedules="schedules"
        @select="methodUpdateSelected" /> -->

      <div class="avaliable-wrap">
        <AvaliableDoctor v-for="s of formatSchedules" :key="s.uuid" :schedule="s" @select="methodUpdateSelected"
          :selected="selected" />
      </div>
    </template>
  </div>
</template>

<script>
/**
 * TODO 日期選擇不作 url 更新
 */
import { isEqual } from 'lodash';
import { mapState } from 'vuex';
import { getWeeksList, formatCustom } from '@/utils/getWeeks';
// import { shiftSettings } from '@/utils/const';
// import fakeList from '@/utils/fake';
import NwSelect from '@/components/NwSelect.vue';
// import ChangeDays from '@/components/ChangeDays.vue';
import { getSchedules } from '@/lib/cakeHttp';
import { shiftSettings } from '@/utils/const';
// import ByDateCalendar from './ByDateCalendar.vue';
import AvaliableDoctor from './AvaliableDoctor.vue';

export default {
  name: 'ByDate',
  props: ['type'],
  components: {
    NwSelect,
    // ChangeDays,
    // ByDateCalendar,
    AvaliableDoctor,
  },
  data() {
    return {
      weeks: [],
      // clinicList: [],
      weekSelected: 0,
      dateSelected: '',
      schedules: [],
      selected: '',
      periodSelected: '1'
    };
  },
  computed: {
    ...mapState(['branchs']),
    range() {
      return (
        // 過濾掉王醫生的特別時段
        // 原本只有濾掉101 但是這樣會多一個上午選項 所以要多濾掉102
        Object.keys(shiftSettings)?.filter((item) => item !== '101' && item !== '102').map((code) => ({
          label: shiftSettings[code]?.label,
          value: code,
        })) ?? []
      );
    },
    rangeList() {
      // 原本只有濾掉101 但是這樣會多一個上午選項 所以要多濾掉102
      const tempList = Object.keys(shiftSettings)?.filter((item) => item !== '101' && item !== '102' && item !== '103').map((code) => ({
        label: shiftSettings[code]?.label,
        value: code
      }));

      return tempList;
    },
    formatSchedules() {
      return this.schedules.map((item) => {
        const { date: d, shift_code: c, no } = item;
        const able = !Number.isNaN(Number(no));
        return {
          ...item,
          date: d,
          shiftCode: c,
          able,
          address: item?.branch?.addr || '',
          branch: item?.branch?.name || '',
          no: able ? '' : no,
        };
      }).filter((item) => item.shiftCode === this.periodSelected);
    },
    clinicList() {
      return this.branchs.filter(({ clinic }) => clinic.includes(+this.type));
    },
    weekList() {
      return this.weeks?.map((list, i) => {
        const start = formatCustom(list?.[0]?.timestamp, 'YYYY/MM/DD');
        const end = formatCustom(list?.[list.length - 1]?.timestamp, 'YYYY/MM/DD');
        return {
          label: `${start} - ${end}`,
          value: i
        };
      });
    },
    dateList() {
      return this.weeks?.[this.weekSelected]?.map(({ date, timestamp }) => ({
        label: formatCustom(timestamp, 'MM/DD(dddd)'),
        value: date,
        timestamp
      }));
    }
  },
  watch: {
    dateList: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          this.dateSelected = newVal?.[0]?.value;
        }
      },
      immediate: true
    },
    dateSelected: {
      handler() {
        this.methodGetSchedules();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    filterPeriod(v) {
      console.log('v', v);
      this.periodSelected = v;
    },
    methodUpdateWeekSelected(v) {
      this.weekSelected = v;
    },
    methodUpdateDateSelected(v) {
      this.dateSelected = v;
    },
    methodGetSchedules() {
      this.schedules = [];
      if (!this.dateSelected) {
        return;
      }

      const { classification } = this.$route.params;
      const classificationName = +classification === 1 ? '婦產科' : '中醫科';

      const queryValue = {
        devision: classificationName,
        start_date: this.dateSelected,
        end_date: this.dateSelected
      };

      getSchedules(
        queryValue,
        (response) => {
          this.schedules = response?.data || [];
        },
        (response) => {
          this.$customSWAL({ icon: 'error', title: response });
        }
      );
    },
    handleSelected(uuid) {
      this.selected = uuid;
    },
    methodUpdateSelected(v) {
      this.handleSelected(v.uuid);
      this.$emit('select', v);
    },
    async initWeek() {
      this.weeks = await getWeeksList();
    },
  },
  created() {
    this.initWeek();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/appointment.scss';

.avaliable-wrap {
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

// @media screen and (min-width: 600px) {
//   .avaliable-wrap {
//     grid-template-columns: repeat(2, 1fr);
//   }
// }

// @media screen and (min-width: 1024px) {
//   .avaliable-wrap {
//     grid-template-columns: repeat(3, 1fr);
//   }
// }
</style>
