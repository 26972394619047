<template>
  <div class="by-wrapper">
    <div class="by-wrapper__select">
      <!-- <NwSelect
        :selected="clinicSelected"
        :list="clinicList"
        @update="methodUpdateClinicSelected"
      /> -->
      <NwSelect :selected="doctorSelected" :list="doctorList" :placeholder="$t('customSWALSelectDoctor')"
        @update="methodUpdateDoctorSelected" />
      <NwSelect :selected="weekSelected" :list="weekList" :placeholder="$t('customSWALSelectDate')"
        @update="methodUpdateWeekSelected" compareKey="value" />
      <NwSelect :selected="dateSelected" :list="dateList" :placeholder="$t('customSWALSelectDate')"
        @update="methodUpdateDateSelected" compareKey="value" />
    </div>
    <hr class="hr-default" />
    <!-- <ChangeWeeks :active="weekIndex" :weeks="weeks" @update="methodUpdateWeekIndex" />
    <ByDoctorCalendar :weeks="weeks[weekIndex]" :schedules="schedules" @select="methodUpdateSelected" /> -->

    <div class="avaliable-wrap">
      <AvaliableDateLocation v-for="s of formatSchedules" :key="s.uuid" :schedule="s" @select="methodUpdateSelected"
        :selected="selected" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NwSelect from '@/components/NwSelect.vue';
import { getSchedules } from '@/lib/cakeHttp';

// import fakeList from '@/utils/fake';
// import fakeDoctorList from '@/utils/fakeDoctorList';
// import ChangeWeeks from '@/components/ChangeWeeks.vue';
import { shiftCodes } from '@/utils/const';
import { getWeeksList, formatCustom } from '@/utils/getWeeks';
// import ByDoctorCalendar from './ByDoctorCalendar.vue';
import { isEqual } from 'lodash';
import AvaliableDateLocation from './AvaliableDateLocation';

export default {
  name: 'ByDoctor',
  props: ['type'],
  components: {
    NwSelect,
    // ChangeWeeks,
    // ByDoctorCalendar,
    AvaliableDateLocation
  },
  data() {
    return {
      doctorSelected: null,

      shiftCodes,
      weekIndex: 0,
      weeks: [],
      schedules: [],
      selected: '',
      weekSelected: 0,
      dateSelected: '',

    };
  },
  computed: {
    ...mapState(['doctors', 'branchs']),
    // 前人接的有先處理過，所以 ... 就按照前人的智慧也先過一段處理
    formatSchedules() {
      return this.schedules.map((item) => {
        const { date: d, shift_code: c, no } = item;
        const able = !Number.isNaN(Number(no));
        return {
          ...item,
          date: d,
          shiftCode: c,
          able,
          address: item?.branch?.addr || '',
          branch: item?.branch?.name || '',
          no: able ? '' : no,
        };
      });
    },
    weekList() {
      console.log(this.weeks);
      return this.weeks.map((item, idx) => {
        const startDate = item[0].date;
        const endDate = item[5].date;
        const start = `${startDate}`;
        const end = `${endDate}`;

        return ({
          label: `${start} ~ ${end}`,
          value: idx,
        });
      });
    },
    clinicList() {
      return this.branchs.filter(({ clinic }) => clinic.includes(+this.type));
    },
    doctorList() {
      const { classification } = this.$route.params;
      const classificationName = +classification === 1 ? '婦產科' : '中醫科';
      const list = this.doctors.filter(({ devision }) => devision === classificationName);
      return list;
    },
    dateList() {
      return this.weeks?.[this.weekSelected]?.map(({ date, timestamp }) => ({
        label: formatCustom(timestamp, 'MM/DD(dddd)'),
        value: date,
        timestamp
      }));
    },
    init() {
      return this.doctorList.length > 0 && this.clinicList.length > 0;
    },

  },
  watch: {
    init: {
      handler(val) {
        if (val === true) {
          this.initPage();
        }
      },
      immediate: true
    },
    'doctorSelected.value': {
      async handler() {
        await getWeeksList();
        await this.methodGetSchedules();
      }
    },
    dateList: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          this.dateSelected = newVal?.[0]?.value;
        }
      },
      immediate: true
    },
    dateSelected: {
      handler() {
        this.methodGetSchedules();
      },
      deep: true,
      immediate: true
    },
    weekIndex() {
      this.methodGetSchedules();
    }
  },
  mounted() {
    // this.methodGetWeeksList();
  },
  methods: {
    initPage() {
      const {
        query: { doctor = null }
      } = this.$route;
      if (doctor) {
        const doctorData = this.doctorList.find(({ label }) => label === doctor) || null;
        this.methodUpdateDoctorSelected(doctorData);
      }
    },
    methodUpdateWeekSelected(v) {
      this.weekSelected = v;
    },
    // ...mapGetters(['getDevisionDoctors']),
    // 更新選擇的醫師
    methodUpdateDoctorSelected(v) {
      this.doctorSelected = v;
      const { query } = this.$route;
      if (query?.doctor !== v.label) {
        this.$router.replace({ query: { ...query, doctor: v.label } });
      }
    },
    methodUpdateDateSelected(v) {
      this.dateSelected = v;
    },
    async methodGetSchedules() {
      console.log('i am methodGetSchedules');
      this.schedules = [];
      if (!this.doctorSelected?.value) {
        return;
      }
      // const weekDate = this.weeks[this.weekIndex];
      const queryValue = {
        doctor_id: this.doctorSelected.value,
        // start_date: weekDate[0].date,
        // end_date: weekDate[5].date
        start_date: this.dateSelected,
        end_date: this.dateSelected
      };
      getSchedules(
        queryValue,
        (response) => {
          this.schedules = response?.data || [];
        },
        (response) => {
          this.$customSWAL({ icon: 'error', title: response });
        }
      );
    },
    // 初始化資料
    // methodGetWeeksList() {
    //   const list = fakeList?.filter(
    //     ({ name = '', date, shift_code: shiftCode }) =>
    //       isSameOrBeforeDate(`${date} ${shiftSettings?.[shiftCode]?.startTime ?? ''}`) &&
    //       name === this.doctorSelected.label
    //   );
    //   const schedulesFlattenByDay = {};
    //   list.forEach((item, i) => {
    //     const { date, shift_code: shiftCode } = item;
    //     // 如果無資料，給預設
    //     if (!schedulesFlattenByDay[date]) {
    //       schedulesFlattenByDay[date] = {
    //         [shiftCodes.morning]: [],
    //         [shiftCodes.afternoon]: [],
    //         [shiftCodes.evening]: [],
    //       };
    //     }

    //     const { uuid, date: d, shift_code: c, no, branch_no: branchNo, name, clinic, devision } = item;
    //     const able = !Number.isNaN(Number(no));
    //     schedulesFlattenByDay[date][shiftCode].push({
    //       uuid,
    //       date: d,
    //       shiftCode: c,
    //       able,
    //       no: able ? '' : no,
    //       branchNo,
    //       name,
    //       clinic,
    //       branch: devision,
    //       color: this.tmpColorList[i],
    //     });
    //   });
    //   this.schedules = schedulesFlattenByDay;
    // },
    // 更新week active
    methodUpdateWeekIndex(v) {
      console.log('v...', v.value);
      this.weekIndex = v.value;
      this.weekSelected = v;
    },
    handleSelected(uuid) {
      this.selected = uuid;
    },
    methodUpdateSelected(v) {
      this.handleSelected(v.uuid);
      this.$emit('select', v);
    },
    async initWeek() {
      this.weeks = await getWeeksList();
    },
  },
  created() {
    this.initWeek();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/appointment.scss';

.avaliable-wrap {
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

// @media screen and (min-width: 600px) {
//   .avaliable-wrap {
//     grid-template-columns: repeat(2, 1fr);
//   }
// }

// @media screen and (min-width: 1024px) {
//   .avaliable-wrap {
//     grid-template-columns: repeat(3, 1fr);
//   }
// }
</style>
