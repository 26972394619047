<template>

  <div class="avaliable-date" @click="handleSelect">
    <div class="info">
      <div class="info__basic">
        <p class="info__basic__date">{{ schedule.date }} ({{ weekDay }})</p>
        <p class="info__basic__time-room">{{ shiftText }}／{{ $t(schedule.clinic) }}</p>
      </div>

      <p class="info__remark" v-if="remark">
        {{ remark }}
      </p>

    </div>

    <p class="appointment" :class="{
      'appointment--enable': appointmentStatus,
      'appointment--active': appointmentStatus && selected === schedule.uuid
    }">
      <span class="appointment__text">{{ appointmentText }}</span>
      <img class="appointment__icon" src="@/assets/icons/arrow-right__white.svg"
        v-if="!appointmentStatus || selected === schedule.uuid" />
      <img class="appointment__icon" src="@/assets/icons/arrow-right__main.svg" v-else />
    </p>

  </div>

</template>

<script>
import { shiftSettings } from '@/utils/const';
import { formatCustom } from '@/utils/getWeeks';
import { getApiLangCode } from '@/utils/getLangCode';

export default {
  name: 'AvaliableDate',
  props: {
    schedule: {
      type: Object,
      default: () => ({})
    },
    selected: {
      type: String,
      defualt: ''
    }

  },
  computed: {
    shiftText() {
      return shiftSettings?.[this.schedule.shift_code]?.label ?? '';
    },
    weekDay() {
      return formatCustom(this.schedule.date, 'dd');
    },
    enableAppointment() {
      return !Number.isNaN(Number(this.schedule.no));
    },
    appointmentText() {
      if (!this.enableAppointment) return this.$t(this.schedule.no);
      return this.$t('book');
    },
    appointmentStatus() {
      if (!this.enableAppointment) return false;
      return true;
    },
    remark() {
      if (!this.schedule?.remark) {
        return '';
      }

      const langCode = getApiLangCode();
      return this.schedule.remark[langCode];
    }
  },
  methods: {
    handleSelect() {
      if (!this.enableAppointment) {
        return;
      }
      this.$emit('select', this.schedule);
    }
  }

};

</script>

<style lang="scss" scoped>
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";

.avaliable-date {

  border-radius: 10px;

  &+& {
    margin-top: 30px;
  }
}

.info {
  @include padding(16px 24px);
  border-top: 1px solid $c-assist2;
  border-right: 1px solid $c-assist2;
  border-left: 1px solid $c-assist2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  &__basic {

    &__date {
      @include font-style($c-black, 18, 500, 1.35px, 24px);

    }

    &__time-room {
      @include font-style($color-theme, 14, 400, 1px, 20px);
      margin-top: 16px;
    }
  }

  &__remark {
    margin-top: 10px;
    border-top: 1px solid $c-assist2;
    @include font-style($c-assist3, 14, 400, 1px);
    padding-top: 10px;
  }
}

.appointment {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  @include padding(5px 20px);
  text-align: right;
  @include flex(flex-end);
  background-color: $c-appointment-disable;
  cursor: not-allowed;

  &--enable {
    background-color: $c-appointment-enable;
    color: $color-theme;
    cursor: pointer;

    .appointment__text {
      color: $color-theme;
    }
  }

  &--active {
    background-color: $color-theme;
    cursor: pointer;

    .appointment__text {
      color: $c-white;
    }
  }

  &__text {
    @include font-style($c-white, 14);
  }

  &__icon {
    width: 16px;
  }
}
</style>
