<template>
  <div class="select-wrapper">
    <button v-for="item in list"
      :key="`select-item-${item.label}`"
      @click="methodChangeSelectType(item.value)"
      class="select-item"
      :class="methodIsActive(item.value) ? 'active' : ''">
      <div class="select-item__icon">
        <img :src="imgs[item.icon]"
          alt="" />
      </div>
      <p class="select-item__text">{{ item.label }}</p>
    </button>
  </div>
</template>

<script>
import byClinic from '@/assets/img/byClinic.svg';
import byDate from '@/assets/img/byDate.svg';
import byDoctor from '@/assets/img/byDoctor.svg';
import { selectClinicDateDoctorList } from '@/utils/const';

export default {
  name: 'SelectClinicDateDoctor',
  props: {
    selectType: {
      type: [Number, String],
      default: 1,
      required: true
    }
  },
  data() {
    return {
      list: selectClinicDateDoctorList,
      imgs: {
        byClinic,
        byDate,
        byDoctor
      }
    };
  },
  methods: {
    methodIsActive(v) {
      return +this.selectType === +v;
    },
    methodChangeSelectType(val) {
      this.$emit('update', val);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helper/_global.scss';

.select {
  &-wrapper {
    display: flex;
    column-gap: 17px;
    row-gap: 15px;
    @media screen and (max-width: 768px) {
      & {
        flex-direction: column;
      }
    }
  }
  &-item {
    flex: 1;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
    @include shadow-default;
    @include rounded-default;
    @include transition-all;
    &.active {
      background: $primary-700;
    }
    &__icon {
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(-45deg, $gradient-primary-end, $gradient-primary-start);
      padding: 25px;
      border: 1px solid #fff;
      margin-bottom: 28px;
      @include rounded-full;
      @include transition-all;
      img {
        width: 100%;
        height: 100%;
        color: #fff;
      }
      .active & {
        background: transparent;
      }
    }
    &__text {
      color: $ash-600;
      @include transition-all;
      .active & {
        color: white;
      }
    }
  }
}
</style>
