import { mapState, mapMutations } from 'vuex';

export default {
  name: 'registrationProhibitMixin',
  computed: {
    ...mapState(['memberInfo'])
  },
  methods: {
    ...mapMutations([
      'logOut',
      'toggleShowVerifyWarning',
      'toggleShowPrivacyWarning',
      'toggleShowMsg',
      'setMsgInfo'
    ]),
    selectTimeWarning() {
      this.toggleShowMsg(true);
      this.setMsgInfo({
        succeed: false,
        desc: this.$t('customSWALSelectTime'),
        callback: () => {
          this.$router.push({ name: 'Login' });
        },
      });
    },
    loginWarning() {
      this.toggleShowMsg(true);
      this.setMsgInfo({
        succeed: false,
        desc: this.$t('pleaseLogin'),
        callback: () => {
          this.$router.push({ name: 'Login' });
        },
        triggerCallbackOnOverlay: true,
        triggerCallbackOnConfirmBtn: true
      });
    },
    checkPrivacyPop() {
      this.toggleShowPrivacyWarning(true);
    },
    checkPrivacy() {
      const { agree_privicy } = this.memberInfo;

      if (agree_privicy) {
        return true;
      }
      return false;
    },
    /**
     * 跳驗證彈窗
     * @param {'phone' | 'email'  | undefined} type 默認顯示通用文案, 有傳則顯示對應文案
     */
    openkQualificationPop(type) {
      this.toggleShowVerifyWarning({
        status: true,
        type,
        callback: () => {
          this.$router.push({ name: 'MemberData' });
        },
        triggerCallbackOnOverlay: true,
        triggerCallbackOnConfirmBtn: true
      });
    },
    /** v3.0 驗證方案 */
    checkQualificationV3() {
      const { email_verified_at, phone_verified_at } = this.memberInfo;
      /** 需驗證 email/phone其一 */
      if (!(email_verified_at || phone_verified_at)) {
        return false;
      }
      return true;
    },
    checkQualification() {
      const isLogin = this.$cookies.get('api_token');

      if (!isLogin) {
        this.loginWarning();
        return;
      }

      const { agree_privicy, email_verified_at, phone_verified_at } = this.memberInfo;

      // console.log('agree_privicy', agree_privicy);
      // console.log('email_verified_at', email_verified_at);
      // console.log('phone_verified_at', phone_verified_at);

      // console.log(1, agree_privicy, 2, email_verified_at, 3, phone_verified_at);

      if (!agree_privicy) {
        this.toggleShowPrivacyWarning(true);

        return;
      }

      // 沒驗證 email 跳彈窗
      if (!email_verified_at) {
        this.toggleShowVerifyWarning({
          status: true,
          type: 'email',
          callback: () => {
            this.$router.push({ name: 'MemberData' });
          },
          triggerCallbackOnOverlay: true,
          triggerCallbackOnConfirmBtn: true
        });
        return;
      }

      // 沒驗證 手機 跳彈窗
      if (!phone_verified_at) {
        this.toggleShowVerifyWarning({
          status: true,
          type: 'phone',
          callback: () => {
            this.$router.push({ name: 'MemberData' });
          },
          triggerCallbackOnOverlay: true,
          triggerCallbackOnConfirmBtn: true
        });
        return;
      }

      if (this.$route.name === 'Registration') {
        this.dialogOpen = true;
      }
    }

  },
  // created() {
  //   this.checkQualification();
  // }
};
