<template>
  <div>
    <button class="schedule-btn"
      @click="openModal">
      <img src="@/assets/img/schedule.svg"
        alt="">
      <span>{{$t('schedule')}}&emsp;</span>
      <font-awesome-icon icon="chevron-right"
        class="icon" />
    </button>
    <ScheduleImgsModal :show="showModal"
      @close="closeModal"
      :type="classification" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ScheduleImgsModal from '@/components/ScheduleImgsModal.vue';

export default {
  name: 'Schedule',
  props: ['classification'],
  components: {
    ScheduleImgsModal
  },
  data() {
    return {
      file: '',
      showModal: false
    };
  },
  computed: {
    ...mapGetters([
      // 'getLangUUID',
    ])
  },
  methods: {
    openModal() {
      const el = document.getElementsByTagName('body')[0];
      el.classList.add('lock');
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      const el = document.getElementsByTagName('body')[0];
      el.classList.remove('lock');
    }
  }
};
</script>

<style lang="scss" scoped>
.schedule-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #af566d;
  border-radius: 1000px;
  padding: 8px 18px;
  img {
    width: 20px;
  }
  span {
    color: #af566d;
    text-decoration: underline;
    padding-left: 8px;
    padding-right: 10px;
    font-size: 16px;
  }
  .icon {
    margin-top: 1px;
    color: #af566d;
    width: 10px;
  }
}
</style>
