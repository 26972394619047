import dayjs from 'dayjs';
import 'dayjs/locale/zh-tw';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/en';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { getLangCode } from '@/utils/getLangCode';
import { getSysVars2 } from '@/lib/cakeHttp';

dayjs.locale(getLangCode());
dayjs.extend(isoWeek);
dayjs.extend(isSameOrBefore);

async function getupdateDays() {
  const reserveWeek = await getSysVars2();
  return reserveWeek;
}

// const weekDays = 6;
// 網路掛號開放區間：6週 (僅可掛6週內的診次) 原本是10
// const weekCount = 10;
export const getWeeksList = async () => {
  // console.log('i am getWeeksList');
    // 利用後台API控制可預約的週數
  const result = await getupdateDays();
  const weekDays = 7;
  const weekCount = Math.ceil(result / weekDays);

  return Array.from(new Array(weekCount)).map((_, i) => {
    const dayOfWeekFirst = dayjs()
      .add(i, 'w')
      .startOf('isoweek');
    return Array.from(new Array(weekDays)).map((_day, dayIndex) => {
      const day = dayOfWeekFirst.add(dayIndex, 'd');
      return {
        date: day.format('YYYY-MM-DD'),
        timestamp: day.valueOf(),
      };
    });
});
};

export const formatCustom = (date, format = 'YYYY-MM-DD') => {
  if (!date) return '';
  return dayjs(date).format(format);
};

export const isSameOrBeforeDate = (target, base = Date.now(), unit = 'minutes') =>
  !dayjs(target).isSameOrBefore(base, unit);
