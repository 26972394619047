<template>
  <div class="select-button__wrapper">
    <button class="select-button" @click="methodToggleShow()" ref="nwSelect">
      <p class="select-button__label" :class="haveValue ? 'select-button__label--empty' : ''">
        {{ selectedLabel }}
      </p>
      <font-awesome-icon icon="chevron-down" class="select-button__icon" />
    </button>
    <transition
      leave-active-class="transition ease-in duration-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <ul class="select-button__list" v-if="show">
        <li
          v-for="(item, idx) in list"
          :key="`select-item-${item.label}-${idx}`"
          :class="methodItemIsActive(item) ? 'active' : ''"
          @click="onPress(item)"
        >
          {{ item.label }}
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
  import { isArray, isEqual } from 'lodash';
  import i18n from '@/i18n';

  export default {
    name: 'NwSelect',
    props: {
      selected: {},
      placeholder: {
        type: String,
        default: i18n.tc('select')
      },
      list: {
        type: Array,
        default: () => []
      },
      compareKey: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        count: 0,
        show: false,
      };
    },
    computed: {
      haveValue() {
        return !this.selected?.label;
      },
      selectedLabel() {
        if (this.compareKey) {
          const target = this.list.find((v) => v[this.compareKey] === this.selected);
          return target?.label ?? this.placeholder;
        }
        return this.selected?.label ?? this.placeholder;
      },
    },
    mounted() {
      window.addEventListener('click', this.methodDetectToClose);
    },
    methods: {
      methodDetectToClose(v) {
        const target =
          v && isArray(v?.composedPath()) ? v?.composedPath()?.find((e) => e === this.$refs?.nwSelect) ?? null : null;
        if (this.show && this.count > 0 && !target) {
          this.show = false;
          this.count = 0;
        }
        if (this.show) this.count += 1;
      },
      methodToggleShow(status = !this.show) {
        this.show = status;
      },
      onPress(v) {
        if (this.compareKey) {
          this.$emit('update', v[this.compareKey]);
        } else {
          this.$emit('update', v);
        }
      },
      methodItemIsActive(v) {
        if (this.compareKey) {
          return isEqual(v[this.compareKey], this.selected);
        }
        return isEqual(v, this.selected);
      },
    },
    beforeDestroy() {
      window.removeEventListener('click', this.methodDetectToClose);
      this.show = false;
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/helper/_global.scss';
  .select-button {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 14px 52px 14px 20px;
    &__wrapper {
      position: relative;
      display: inline-flex;
    }
    &__label {
      &--empty {
        color: $ash-600;
      }
    }
    &__icon {
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
    }
    &__list {
      position: absolute;
      top: 100%;
      min-width: 100%;
      background-color: #f5f5f5;
      border-radius: 8px;
      z-index: 10;
      margin-top: 6px;
      max-height: 40vh;
      overflow-y: auto;
      li {
        padding: 10px 20px;
        cursor: pointer;
        @include transition-all;
        white-space: nowrap;
        &.active,
        &:hover {
          background-color: $primary-300;
        }
      }
    }
  }
</style>
