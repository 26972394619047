<template>
  <div class="by-wrapper">
    <div class="by-wrapper__select">
      <NwSelect :selected="clinicSelected" :list="clinicList" :placeholder="$t('customSWALSelectBranch')"
        @update="methodUpdateClinicSelected" />
      <NwSelect :selected="doctorSelected" :list="doctorList" :placeholder="$t('customSWALSelectDoctor')"
        @update="methodUpdateDoctorSelected" />
      <NwSelect :selected="weekSelected" :list="weekList" placeholder="請選擇日期" @update="methodUpdateWeekIndex" />
    </div>
    <hr class="hr-default" />
    <!-- <ChangeWeeks :active="weekIndex" :weeks="weeks" @update="methodUpdateWeekIndex" /> -->
    <!-- <ByClinicCalendar :weeks="weeks[weekIndex]" :schedules="schedules" @select="methodUpdateSelected" /> -->
    <div class="avaliable-wrap">
      <AvaliableDate v-for="s of formatSchedules" :key="s.uuid" :schedule="s" @select="methodUpdateSelected"
        :selected="selected" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import NwSelect from '@/components/NwSelect.vue';
import { getSchedules } from '@/lib/cakeHttp';
import { getWeeksList } from '@/utils/getWeeks';
// import fakeList from '@/utils/fake';
// import ChangeWeeks from '@/components/ChangeWeeks.vue';
import { shiftCodes } from '@/utils/const';
// import ByClinicCalendar from './ByClinicCalendar.vue';
import AvaliableDate from './AvaliableDate.vue';

export default {
  name: 'ByClinic',
  props: ['type'],
  components: {
    NwSelect,
    // ChangeWeeks,
    // ByClinicCalendar,
    AvaliableDate
  },
  data() {
    return {
      clinicSelected: null,
      doctorSelected: null,
      weekSelected: null,
      shiftCodes,
      weekIndex: 0,
      weeks: [],
      schedules: [],
      selected: '',
    };
  },
  computed: {
    ...mapState(['doctors', 'branchs']),
    // 前人接的有先處理過，所以 ... 就按照前人的智慧也先過一段處理
    formatSchedules() {
      return this.schedules.map((item) => {
        const { date: d, shift_code: c, no } = item;
        const able = !Number.isNaN(Number(no));
        return {
          ...item,
          date: d,
          shiftCode: c,
          able,
          address: item?.branch?.addr || '',
          branch: item?.branch?.name || '',
          no: able ? '' : no,
        };
      });
    },
    weekList() {
      return this.weeks.map((item, idx) => {
        const startDate = item[0].date;
        const endDate = item[5].date;
        const start = `${startDate}`;
        const end = `${endDate}`;
        return ({
          label: `${start} ~ ${end}`,
          value: idx,
        });
      });
    },
    doctorList() {
      const { classification } = this.$route.params;
      const list = this.doctors.filter(({ devision }) => devision === (+classification === 1 ? '婦產科' : '中醫科'));
      // 依照院所篩選醫師 (效果不好…)
      if (this.clinicSelected?.value) {
        return list.filter(({ branchs }) => branchs.includes(this.clinicSelected?.id));
      }

      return list;
    },
    clinicList() {
      return this.branchs.filter(({ clinic }) => clinic.includes(+this.type) || clinic.includes(this.type));
    },
    init() {
      return this.doctorList.length > 0 && this.clinicList.length > 0;
    }
  },
  watch: {
    init: {
      async handler(val) {
        if (val === true) {
          this.initPage();
        }
        // 這邊執行一進頁面就先得到資料 渲染班表
        await this.initWeek();
        await this.methodGetSchedules('a');
      },
      immediate: true
    },
    'clinicSelected.value': {
      handler() {

        // this.methodGetSchedules();
      }
    },
    'doctorSelected.value': {
      handler() {
        this.methodGetSchedules();
      }
    },
    weekIndex() {
      this.methodGetSchedules();
    },
    doctorList: {
      handler() {
        if (this.doctorList.length === 0) {
          this.toggleShowMsg(true);
          this.setMsgInfo({
            succeed: false,
            desc: this.$t('noDoctors')
          });

          this.initPage();
        }
      },
      // immediate: true
    }
  },
  methods: {
    ...mapMutations([
      'toggleShowMsg',
      'setMsgInfo'
    ]),
    initPage() {
      const {
        query: { clinic = null, doctor = null }
      } = this.$route;
      if (clinic) {
        const clinicData = this.clinicList.find(({ value }) => value === clinic) || null;
        if (clinicData) {
          this.methodUpdateClinicSelected(clinicData);
        }
      }
      if (doctor) {
        const doctorData = this.doctorList.find(({ label }) => label === doctor) || null;
        console.log(doctorData);
        this.methodUpdateDoctorSelected(doctorData);
      }
    },
    // 更新選擇的診所
    methodUpdateClinicSelected(v) {
      this.clinicSelected = v;
      this.doctorSelected = null;
      const { query } = this.$route;
      if (query?.clinic !== v.value) {
        delete query?.doctor;
        this.$router.replace({ query: { ...query, clinic: v.value } });
      }
    },
    // 更新選擇的醫師
    async methodUpdateDoctorSelected(v) {
      console.log('v', v);
      this.doctorSelected = v;
      const { query } = this.$route;

      if (query?.doctor !== v.label) {
        this.$router.replace({ query: { ...query, doctor: v.label } });
      }
    },
    // 取得排班表
    async methodGetSchedules() {
      this.schedules = [];

      if (this.weeks.length === 0) {
        return;
      }

      if (!this.doctorSelected?.value || !this.clinicSelected?.value) {
        return;
      }

      const weekDate = this.weeks[this.weekIndex];
      // const weekDate = this.weeks[0];

      const queryValue = {
        start_date: weekDate[0]?.date,
        end_date: weekDate[5]?.date,
        doctor_id: this.doctorSelected.value,
        branch_no: this.clinicSelected.value
      };
      getSchedules(
        queryValue,
        (response) => {
          this.schedules = response?.data || [];
        },
        (response) => {
          this.$customSWAL({ icon: 'error', title: response });
        }
      );
    },
    async handleSelected(uuid) {
      this.selected = uuid;
    },
    // 更新week active
    async methodUpdateWeekIndex(v) {
      console.log('v...', v);
      this.weekIndex = v.value;
      this.weekSelected = v;
    },
    methodUpdateSelected(v) {
      this.handleSelected(v.uuid);
      this.$emit('select', v);
    },
    async initWeek() {
      this.weeks = await getWeeksList();
      const [firstWeekData] = this.weekList;
      this.weekSelected = firstWeekData;
    }
  },
  async created() {
    this.initWeek();
    // const [firstWeekData] = this.weekList;
    // this.weekSelected = firstWeekData;
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/appointment.scss';

.avaliable-wrap {
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

// @media screen and (min-width: 600px) {
//   .avaliable-wrap {
//     grid-template-columns: repeat(2, 1fr);
//   }
// }

// @media screen and (min-width: 1024px) {
//   .avaliable-wrap {
//     grid-template-columns: repeat(3, 1fr);
//   }
// }
</style>
